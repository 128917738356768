import { graphql } from 'gatsby';
import * as React from 'react';
import Pages from 'components/Pages';
import TeamDetails from 'components/pages/team/TeamDetails';

const TeamDetailsTemplate = ({ data }) => {
  return (
    <Pages>
      <TeamDetails data={data.teamJson} peopleData={data.allTeamJson.edges} />
    </Pages>
  );
};

export default TeamDetailsTemplate;

export const query = graphql`
  query ($slug: String) {
    teamJson(slug: { eq: $slug }) {
      src
      slug
      name
      location
      index
      headline
      title
      description
      socialMedia {
        twitter
        linkedin
        instagram
        facebook
      }
    }
    allTeamJson {
      edges {
        node {
          src
          slug
          name
          location
          index
          headline
          title
          description
          socialMedia {
            twitter
            linkedin
            instagram
            facebook
          }
        }
      }
    }
  }
`;
