/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';
import FadeInLeft from 'components/animation/FadeInLeft';
import FB_ICON from 'images/fb.svg';
import TW_ICON from 'images/tw.svg';
import IG_ICON from 'images/ig.svg';
import IN_ICON from 'images/in.svg';
import ReactHtmlParser from 'react-html-parser';
import DetailsPageContainer from '../../DetailsPageContainer';
import PropTypes from 'prop-types';

const SocmedToolbarContainer = ({ href, icon }) => (
  <a href={href} target="_blank" rel="noreferrer">
    <div
      style={{
        backgroundImage: `url(${icon})`,
      }}
      className="h-[40px] w-[40px] hover:opacity-50 duration-300 cursor-pointer mr-4"
    />
  </a>
);

const TeamDetails = ({ data, peopleData }) => {
  const [nextPerson, setNextPerson] = useState({});

  useEffect(() => {
    const sortPeople = peopleData?.filter((people) => {
      return people?.node?.headline === data?.headline;
    });
    sortPeople?.forEach((people, index) => {
      if (people?.node?.index === data?.index) {
        if (sortPeople.length === index + 1) {
          setNextPerson(sortPeople[0].node);
        } else {
          setNextPerson(sortPeople[index + 1].node);
        }
      }
    });
  }, [data, peopleData]);

  return (
    <DetailsPageContainer
      pageTitle={data?.name}
      backButtonSlug="/team"
      nextButtonSlug={`/team/${nextPerson.slug}`}
      nextPageTitle={nextPerson.name}
      childrenLeftSection={
        <>
          <div className="col-span-8 lg:col-span-3 w-full h-full flex justify-center">
            <FadeInLeft>
              <img
                src={data?.src}
                alt={data?.name}
                className="object-cover w-full sm:w-[320px] h-[450px]"
              />
            </FadeInLeft>
          </div>
        </>
      }
      childrenRightSection={
        <>
          <div className="flex-row items-center mt-5 hidden lg:flex">
            <p className="text-sm font-thin">{data?.title}</p>
            <span className="mx-4 text-[12px]">●</span>
            <p className="text-sm font-thin">{data?.location}</p>
          </div>
          <div className="mt-10">
            {data?.description && (
              <>
                <p className="text-base">Bio</p>
                <p className="text-sm sm:text-[17px] font-thin leading-relaxed">
                  {ReactHtmlParser(data?.description)}
                </p>
              </>
            )}
            <div className="flex mt-12">
              {data?.socialMedia?.facebook && (
                <SocmedToolbarContainer
                  href={data?.socialMedia?.facebook}
                  icon={FB_ICON}
                />
              )}
              {data?.socialMedia?.twitter && (
                <SocmedToolbarContainer
                  href={data?.socialMedia?.twitter}
                  icon={TW_ICON}
                />
              )}
              {data?.socialMedia?.linkedin && (
                <SocmedToolbarContainer
                  href={data?.socialMedia?.linkedin}
                  icon={IN_ICON}
                />
              )}
              {data?.socialMedia?.instagram && (
                <SocmedToolbarContainer
                  href={data?.socialMedia?.instagram}
                  icon={IG_ICON}
                />
              )}
            </div>
          </div>
        </>
      }
    />
  );
};

SocmedToolbarContainer.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
};

export default TeamDetails;
